<template>
  <div id="top">
    <UserGroupAlert />
    <div class="xs:mx-2 sm:mx-2">
      <slot />
    </div>
    <Footer />
    <UiBackToTopButton />
    <!-- <CookieBanner /> -->
    <LazyUiSnackBar/>
    <AuthForgetPasswordModal />
  </div>
</template>